

























































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { GetPrintList } from "@/request/datacenter";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private parentData: any;
  @Prop()
  private ifVisible: any;
  @Watch("ifVisible", { immediate: true })
  private ifVisibleChange() {
    this.visible = this.ifVisible;
    console.log(this.parentData);
  }
  private filters: any = {
    time: "当天",
  };
  private multipleSelection: any = [];
  private visible: any = false;
  private options: any = ["当天", "近1个月", "近半年", "全部"];
  private data: any = [];
  private ifShowYangsheng: any = true;
  private ifShowGanyu: any = false;
  private cancel() {
    this.$emit("cancel");
  }
  private makeSure() {
    if (this.multipleSelection.length == 0) {
      this.$message.warning("请选择需要打印的报告");
      return;
    }
    let ids: any = [];
    this.multipleSelection.forEach((ele: any) => {
      ids.push(ele.id);
    });
    this.$router.push({
      path: "/main/datacenter/pool/report",
      query: {
        data: JSON.stringify(this.multipleSelection),
        patient: JSON.stringify(this.parentData),
        ifShowGanyu: this.ifShowGanyu,
        ifShowYangsheng: this.ifShowYangsheng,
      },
    });
  }
  private handleSelectionChange(val: any) {
    this.multipleSelection = val;
  }
  private toggleSelection(rows: any) {
    if (rows) {
      rows.forEach((row: any) => {
        (this.$refs.multipleTable as any).toggleRowSelection(row);
      });
    } else {
      (this.$refs.multipleTable as any).clearSelection();
    }
  }
  private selectAll() {
    this.data.forEach((row: any) => {
      (this.$refs.multipleTable as any).toggleRowSelection(row, true);
    });
  }
  private clearAll() {
    this.$confirm("您是否确定清除当前所有选中数据？", "清除", {
      customClass: "commonConfirm",
    }).then(() => {
      (this.$refs.multipleTable as any).clearSelection();
    });
  }
  private search() {
    if (this.$refs.multipleTable) {
      (this.$refs.multipleTable as any).clearSelection();
    }
    this.getList();
  }
  private getList() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params = {
      doctor_id: this.parentData.doctor_id,
      hospital_id: this.parentData.hospital_id,
      patient_id: this.parentData.patient_id,
      date: this.filters.time,
    };
    GetPrintList(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.data = res;
        if (res.length > 0) {
          this.data.forEach((ele: any) => {
            ele.selected = false;
          });
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  mounted() {
    this.getList();
  }
}
